import React from 'react'
import WEnergyLogo from '../assets/images/partner-logos/wenergy-logo.svg'
import BesixLogo from '../assets/images/partner-logos/besix-logo.svg'
import PCLogo from '../assets/images/partner-logos/pc-logo.svg'
import AvapaLogo from '../assets/images/partner-logos/avapa-energy-logo.svg'
import CleanestCharge from '../assets/images/partner-logos/cleanest-charge-logo.svg'
import Valfry from '../assets/images/partner-logos/valfry-logo.png'
import EVA from '../assets/images/partner-logos/eva-logo.png'
import Royal from '../assets/images/partner-logos/Royal_Eijkelkamp_black.png'
import GreenAntz from '../assets/images/partner-logos/GreenAntzLogo.png'


const BorrowerPartners = () => {
  return (
      <section className="section-partners">
        <div className="section-partners-content">
          <h2 className="section-title">
          BORROWER PARTNERS</h2>
          <div className='d-flex justify-content-center'>
          <ul className="partner-logo-list borrowers-partners">
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-finance-announces-a-partnership-with-wenergy-global-to-bring-defi-lending-to-eb5112611457" target="_blank" rel="noreferrer">
                <img src={WEnergyLogo} className="wenergy-logo" alt="WEnergy logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-finance-partners-with-besix-entering-the-mena-region-bed7a9a18353" target="_blank" rel="noreferrer">
                <img src={BesixLogo} className="besix-logo" alt="Besix logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-partners-with-psychology-cares-aims-to-enable-psychology-and-neuroscience-research-82493cd2b224" target="_blank" rel="noreferrer">
                <img src={PCLogo} className="pc-logo" alt="Psychology Cares logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-avapa-energy-partners-with-silta-finance-to-explore-defi-for-the-future-funding-of-solar-2896d9524ff8" target="_blank" rel="noreferrer">
                <img src={AvapaLogo} className='avapa-logo' alt="Avapa logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/cleanestcharge-partners-with-silta-finance-looking-for-defi-to-supercharge-its-mission-to-scale-d0fc4cba2522" target="_blank" rel="noreferrer">
                <img src={CleanestCharge} className='cleanest-charge' alt="Cleanest Charge logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/valfry-and-silta-finance-sign-a-partnership-to-channel-defi-towards-a-collective-green-energy-16740524bbb6" target="_blank" rel="noreferrer">
                <img src={Valfry} className='valfry-logo' alt="Valfry logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/eva-partners-with-silta-finance-in-aims-to-take-highways-to-the-skies-with-the-help-of-defi-b412656d7c43" target="_blank" rel="noreferrer">
                <img src={EVA} className='EVA-logo' alt="EVA logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-and-royal-eijkelkamp-partner-in-aims-to-channel-financing-towards-nature-inclusive-e2c1a9732a2b" target="_blank" rel="noreferrer">
                <img src={Royal} alt="Royal Eijkelkamp logo" className='royal-logo'/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-partners-with-green-antz-to-direct-finance-towards-tackling-and-upcycling-plastic-waste-b73ca1273048" target="_blank" rel="noreferrer">
                <img src={GreenAntz} alt="Green Antz logo" className='royal-logo'/>
              </a>
            </li>
          </ul>
          </div>
        <div>
            </div>
        </div>
    </section>
  )
}

export default BorrowerPartners
import React, { Component } from "react";
import Slider from "react-slick";
import { stepsData } from '../data'

export default class SliderHowItWorks extends Component {


  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    };

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {

    var settings = {
      className: "sangre-slider roadmap-slider",
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      initialSlide: 0,
      variableWidth: false,
      centerMode: false,
      // responsive: [
      //   {
      //     breakpoint: 1399,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //       initialSlide: 0,
      //     }
      //   },
      //   {
      //     breakpoint: 1190,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 1,

      //     }
      //   },
      //   {
      //     breakpoint: 1024,
      //     settings: {
      //       slidesToShow: 1.5,
      //       slidesToScroll: 1
      //     }
      //   },
      // ],
      responsive: [
        {
          breakpoint: 1720,
          settings: {
            slidesToShow: 3.1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1550,
          settings: {
            slidesToShow: 2.8,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };
    return (
      <section className="roadmap-slider-section">
        <div className="roadmap-slider-container steps-slider-container">
          <div className="slider-bg"/>
          <div className="road-slider-container">
            <div className="slider-title">
              <div className='container'>
                <h2>HOW IT WORKS</h2>
                <div className="custom-slick-arrows arrows-roadmap">
                  <button className="custom-slick-arrow-roadmap custom-slick-prev-roadmap" onClick={this.previous}>
                    Previous
                  </button>
                  <button className="custom-slick-arrow-roadmap custom-slick-next-roadmap" onClick={this.next}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-slider container">
            <Slider ref={c => (this.slider = c)} {...settings} >
                  {
                    stepsData.map((item) =>
              <div className="slide-content">
                <div className="roadmap-item roadmap-item-steps">
                  <div className="item-title">
                    <span className="milestone">STEP {item.step}</span>
                  </div>
                  <p className="item-title-type">
                    {item.title}
                  </p>
                  <div className="item-content">
                    {item.text}
                </div>
              </div>
            </div>
                    )
                  }
            </Slider>
            {/* <a className="see-faqs d-flex align-items-center justify-content-end" href="#">
              SEE FAQs
              <svg className="ml-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83301 10.1666L10.1663 1.83325M10.1663 1.83325H1.83301M10.1663 1.83325V10.1666" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a> */}
          </div>
        </div>
      </section>
    );
  }
}
import React, { Component } from "react";
import Slider from "react-slick";
import { stepsData } from '../data'

export default class SliderHowItWorksXs extends Component {
  
  
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state =  {
      currentSlide: 0
    };
    };

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  
  render() {

    var settings = {
      className: "sangre-slider roadmap-slider",
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      afterChange: (current) => this.setState({currentSlide: current}),
      variableWidth: false,
      responsive: [
        {
            breakpoint: 1199,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots: true,
              initialSlide: 0,
              variableWidth: false,
            }
          }
    ]
    };
    return (
      <section className="roadmap-slider-section roadmap-section-xs">
        <div className="roadmap-slider-container-xs">
        {/* <div className="slider-bg"/> */}
        <div className="road-slider-container">
        <div className="slider-title">
          <div className='container d-flex justify-content-between align-items-baseline'>
            <h2 className="section-title">HOW IT WORKS</h2>
            <div className="custom-slick-arrows ">
              <button className="custom-slick-arrow custom-slick-prev steps-arrow" disabled={this.state.currentSlide === 0} onClick={this.previous}>
                Previous
              </button>
              <button className="custom-slick-arrow custom-slick-next steps-arrow" disabled={this.state.currentSlide === 5} onClick={this.next}>
                Next
              </button>
            </div>
        </div>
      </div>
        </div>
        <div className="container-slider container">
          <Slider ref={c => (this.slider = c)} {...settings} >
                {
                  stepsData.map((item) => 
            <div className="slide-content">
              <div className="roadmap-item roadmap-item-steps">
                <div className="item-title">
                  <span className="milestone">STEP {item.step}</span>
                </div>
                <p className="item-title-type">
                  {item.title}
                </p>
                <div className="item-content">
                  {item.text}
              </div>
            </div>
          </div>
                  )
                }
          </Slider>
        </div>
      </div>
      </section>
          );
        }
      }
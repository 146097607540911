import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import Main from '../components/main'
import Contact from '../components/contact'
import Newsletter from '../components/newsletter'
import BorrowerPartners from '../components/borrowerPartners'
import SliderHowItWorks from '../components/sliderHowItWorks'
import SliderHowItWorksXs from '../components/sliderHowItWorksXs'
import ScrollToTop from '../components/ScrollToTop'

const Borrowers = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <Layout>
        <Seo title="Borrowers"/>
        <ScrollToTop />
        <div className='fixed-header'>
        <Hero />
        </div>
        <Main title="The Silta Score is a due diligence rating for an infrastructure project."
        text="Silta offers a streamlined and cost-optimised process for due diligence and impact assessment, helping sustainability-focused infrastructure developers gain faster access to finance. Silta Score quantifies a rating for due diligence and impact, making it easier for financiers to assess lending opportunities." />
        <SliderHowItWorks />
        <SliderHowItWorksXs />
        <Contact />
        <BorrowerPartners />
        <Newsletter />
    </Layout>
}

export default Borrowers
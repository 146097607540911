export const roadmapData = [
    {
        milestone: 'Milestone 0 - ROEBLING',
        state: '88% Complete',
        name: 'Development',
        info: [
            {
                state: 'Done',
                text: 'Design and develop the token model'
            },
            {
                state: 'Done',
                text: 'Launch the first version of Silta portal and website'
        },
            {
                state: 'Done',
                text: 'Implement the loan application process for borrowers'
            },
            {
                state: 'In progress',
                text: 'Model token incentives for delivering SDG impact'
            }
            ]
    },
    {
        milestone: 'Milestone 0 - ROEBLING',
        state: '50% Complete',
        name: 'Governance',
        info: [
            {
                state: 'In progress',
                text: 'Draft the governance model for the Silta community'
            },
            {
                state: 'In progress',
                text: 'Develop and implement a voting mechanism'
            },
            {
                state: 'In progress',
                text: 'Test and launch the governance model'}

            ]
    },{
        milestone: 'Milestone 0 - ROEBLING',
        state: '63% Complete',
        name: 'Silta Score',
        info: [
            {
                state: 'Done',
                text: 'Design the initial framework for Silta Score'},
            { 
                state: 'In progress',
                text: 'Develop the concept for embedding Silta Score into NFT'
            }
            ]
    },
    {
        milestone: 'Milestone 0 - ROEBLING',
        state: '100% Completed',
        name: 'Partnerships and growth',
        info: [
            {
                state: 'Done',
                text: 'Build a pipeline of borrowers and complete prequalification'
            },
            {
                state: 'Done',
                text: 'Explore loan requirements and loan terms with borrowers'
            },
            {
                state: 'Done',
                text: 'Establish partnerships with like-minded DeFi protocols'
            }
            ]
    },
    {
        milestone: 'Milestone 1 - TELFORD',
        state: 'In progress',
        name: 'Development',
        info: [
            {
                state: 'In progress',
                text: 'Integrate Silta with third-party DeFi protocols'
            },
            {
                state: 'In progress',
                text: 'Refine the token model, incentives and bonding curves'
            },
            {
                state: 'In progress',
                text: 'Introduce token staking opportunities'
            }
        ]
    },
    {
        milestone: 'Milestone 1 - TELFORD',
        state: 'In progress',
        name: 'Governance',
        info: [
            {
                state: 'In progress',
                text: 'Develop the process for Silta Improvement Proposals (SIP)'
            },
            {
                state: 'In progress',
                text: 'Election of the first Silta Scoring Committee by the Silta community'
            },
            {
                state: 'In progress',
                text: 'Review and validation of a Silta score by the Scoring Committee'
            }
            ]
    },
    {
        milestone: 'Milestone 1 - TELFORD',
        state: '50% Complete',
        name: 'Silta Score',
        info: [
            {
                state: 'In progress',
                text: 'Finalise the framework for the Silta Score'
            },
            {
                state: 'In progress',
                text: 'Manually complete the scoring process for a loan application'
            },
            {
                state: 'In progress',
                text: 'Produce a due diligence report and mint an NFT for the Silta Score'
            },
            {
                state: 'In progress',
                text: 'Monitor the performance of a borrower during the loan term'
            }
            ]
    },
    {
        milestone: 'Milestone 1 - TELFORD',
        state: '58% Complete',
        name: 'Partnerships and growth',
        info: [
            {
                state: 'In progress',
                text: 'Work with a third-party DeFi protocol to launch a loan pool for a borrower'
            },
            {
                state: 'Done',
                text: 'Maintain and expand a pipeline of borrowers and complete prequalification'
            },
            {
                state: 'In progress',
                text: 'Establish additional partnerships with TradFi and DeFi protocols and projects'
            }
            ]
    },
    {
        milestone: 'Milestone 2 - BRUNEL',
        state: '7% Complete',
        name: 'Development',
        info: [
            {
                state: 'In progress',
                text: 'Decentralise the Silta scoring process where appropriate'
            },
            {
                state: 'In progress',
                text: 'Produce guidance material and training for the scoring process'
            },
            {
                state: 'In progress',
                text: 'Integrate Silta with additional financing partners.'
            }
            ]
    },
    {
        milestone: 'Milestone 2 - BRUNEL',
        state: 'In progress',
        name: 'Governance',
        info: [
            {
                state: 'In progress',
                text: 'The nomination and selection of the first Due Diligence Advisors'
            },
            {
                state: 'In progress',
                text: 'Participation of the Due Diligence Committee in all Silta Score reviews'
            },
            {
                state: 'In progress',
                text: 'Possible introduction of subregional Silta Scoring Committees'
            }
            ]
    },
    {
        milestone: 'Milestone 2 - BRUNEL',
        state: 'In progress',
        name: 'Silta Score',
        info: [
            {
                state: 'In progress',
                text: 'Support Due Diligence Advisors in the scoring process'
            },
            {
                state: 'In progress',
                text: 'Refine Silta Score methodology where needed'
            },
            {
                state: 'In progress',
                text: 'Track the borrowers’ performance against SDG targets'
            }
            ]
    },
    {
        milestone: 'Milestone 3 - STRAUSS',
        state: '',
        name: 'Partnerships and growth',
        info: [
            {
                state: '',
                text: 'The aim is for Silta to be ultimately run by a hybrid of Silta Finance team and its community, but it’s premature to spell out the activities years down the line in detail. In Milestone 2, in consultation with the community, we will be defining the targets in development, governance, decentralization, scoring and other key areas for Milestone 3, the final leg of Silta development.'
            }
            ]
    }
]

export const stepsData = [
    {
        step: 1,
        title: 'Fill in an application to pre-qualify',
        text:'Launch the Silta App, create an account and log in the borrower portal. Fill in and submit an application for your project. Silta makes an initial assessment to check if the project fulfils the pre-qualification criteria.'
    },
    {
        step: 2,
        title: 'Get your project listed on the Silta App.',
        text:'Pre-qualified projects are listed on the Silta App and brought to the attention of financiers and the Silta community. The listing includes basic information about your project based on the preliminary application.'
    },
    {
        step: 3,
        title: 'Submit a full application',
        text:'Once the project is listed in the Silta App, you´ll be invited to submit a full application into a due diligence process. The application is assessed, your project is evaluated, and a Silta Score is assigned to it.'
    },
    {
        step: 4,
        title: 'Recieve your Silta Score',
        text:'After the due diligence process is completed, you´ll receive a due diligence report and the Silta Score. The score quantifies the viability of your project and its expected sustainability impact.'
    },
    {
        step: 5,
        title: 'Connect with financiers',
        text:'Financiers are given access to the Silta Score and the due diligence report. They are invited to make loan offers to fund your project.'
    },
    {
        step: 6,
        title: 'Receive rewards for delivering impact',
        text:'Once financed, Silta monitors the performance of your  project during construction and operations. If the project demonstrates quantifiable and verified impact, it is eligible for rewards.'
    }
]